<template>
  <div class="content">
    <div class="function nh">
      <div class="function-title">能耗管理系统</div>
      <div class="nh-text">
        对电、水、气、热（冷）量等集中采集与分析，对企业端各类能耗进行细分和统计，展示各类能源的使用消耗情况，通过精细化的管理找出高耗能点或不合理的耗能习惯，有效节约能源，为用户进一步节能改造或设备升级提供准确的数据支撑。
      </div>
      <div class="nh-box">
        <div class="nh-a">
          <div class="nh-title">重点区域监测</div>
          <div class="nh-grid">
            <div class="grid-left">
              <span>生产厂房</span>
            </div>
            <div class="grid-right">
              <div class="grid-line"><i></i> <span>实时区域能耗监测</span></div>
              <div class="grid-line"><i></i> <span>设备运行状况监测</span></div>
              <div class="grid-line"><i></i> <span>实时掌握能耗情况</span></div>
              <div class="grid-line"><i></i> <span>关键事件记录</span></div>
            </div>
          </div>

          <div class="nh-grid">
            <div class="grid-left bg2">
              <span>数据中心</span>
            </div>
            <div class="grid-right">
              <div class="grid-line" style="height: 50%">
                <i></i>
                <span style="transform: translateY(50%)"
                  >实现园区重点区域用能情况监测</span
                >
              </div>
              <div class="grid-line" style="height: 50%">
                <i></i> <span>识别非正常能耗</span>
              </div>
            </div>
          </div>
        </div>
        <div class="nh-a">
          <div class="nh-title">重点负荷监测</div>
          <div class="nh-grid">
            <div class="grid-left bg3">
              <span>照明</span>
            </div>
            <div class="grid-right">
              <div class="grid-line"><i></i> <span>实时负荷能耗监测</span></div>
              <div class="grid-line"><i></i> <span>负荷动态能耗分析</span></div>
              <div class="grid-line"><i></i> <span>负荷管理控制</span></div>
              <div class="grid-line"><i></i> <span>有序用电管理</span></div>
            </div>
          </div>

          <div class="nh-grid">
            <div class="grid-left bg4">
              <span>空调暖通</span>
            </div>
            <div class="grid-right">
              <div class="grid-line" style="height: 50%">
                <i></i>
                <span style="transform: translateY(50%)"
                  >实现园区内重点设备用能情况监测</span
                >
              </div>
              <div
                class="grid-line"
                style="height: 50%; transform: translateY(-10%)"
              >
                <i></i>
                <span style="transform: translateY(50%)"
                  >高峰值期限电保电数据支持</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="nh-a">
          <div class="nh-title">能量预警系统</div>
          <div class="nh-grid">
            <div class="grid-left bg5">
              <span>预警</span>
            </div>
            <div class="grid-right">
              <div class="grid-line"><i></i> <span>预制能耗预警值</span></div>
              <div class="grid-line"><i></i> <span>自动发出预警信息</span></div>
              <div class="grid-line" style="transform: translateY(-120%)">
                <i></i>
                <span style="transform: translateY(70%)"
                  >结合OA、eSpace办公系统短信、消息通知</span
                >
              </div>
            </div>
          </div>

          <div class="nh-grid">
            <div class="grid-left bg6">
              <span>消息</span>
            </div>
            <div class="grid-right">
              <div class="grid-line">
                <i></i>
                <span style="transform: translateY(50%)"
                  >及时了解能耗超标动态</span
                >
              </div>
              <div class="grid-line" style="transform: translateY(50%)">
                <i></i> <span>及时调整用能</span>
              </div>
              <div class="grid-line"><i></i> <span>自动发出预警信息</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.function {
  padding: 0 10%;
  margin-bottom: 50px;
  .function-title {
    // text-align: center;
    padding: 30px 0;
    font-size: 40px;
    font-weight: 600;
    color: #333333;
  }

  .scenes-box {
    .scenes-bg {
      width: 100%;
      height: 420px;
      background: url("../../../../assets/image/parkImg/scenes.png") no-repeat;
      background-size: 100%;
    }
    .scenes-text {
      width: 100%;
      height: 400px;
      text-align: center;
      background-color: #fff;
      .title {
        font-size: 28px;
        font-weight: 600;
        padding: 20px 0;
        color: #333333;
      }
      .text-box {
        display: flex;
        .text-box-item {
          width: 16%;
          font-size: 16px;
          color: #6b6b6b;
          margin-top: 60px;
          border-right: 1px solid #dfdfdf;
          p {
            padding: 10px 0;
          }
        }
      }
    }
  }
  .services {
    width: 100%;
    height: 500px;
    display: flex;
    background-color: #fff;
    .left {
      width: 65%;
      height: 500px;
      background: url("../../../../assets/image/parkImg/services.png") no-repeat;
      background-size: 100%;
    }
    .right {
      width: 30%;
      padding: 6%;
      box-sizing: border-box;
      div {
        font-size: 24px;
        color: #6b6b6b;
      }
    }
  }
  .nh-text {
    font-size: 28px;
    margin: 20px 0 40px 0;
    color: #6b6b6b;
  }
  .nh-box {
    display: flex;
    text-align: center;
    justify-content: space-between;

    .nh-a {
      width: 32%;
      .nh-title {
        width: 100%;
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        color: #3651d8;
        background-color: #d6ddff;
      }
      .nh-grid {
        display: flex;
        margin: 3% 0;
        .grid-left {
          width: 60%;
          height: 200px;
          background: url("../../../../assets/image/parkImg/nh1.png");
          padding: 5%;
          text-align: left;
          box-sizing: border-box;
          span {
            color: #fff;
            font-size: 20px;
          }
        }
        .grid-right {
          width: 40%;
          height: 200px;
          padding: 3%;
          display: flex;
          text-align: left;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          background-color: #fff;
          .grid-line {
            font-size: 0.8vw;
            color: #6b6b6b;
            height: 25%;
            i {
              vertical-align: middle;
              display: inline-block;
              width: 6px;
              height: 6px;
              margin: 0 5%;
              background: #3651d8;
              border-radius: 50%;
            }
            span {
              display: inline-block;
              width: 80%;
            }
          }
        }
      }
      .bg2 {
        background: url("../../../../assets/image/parkImg/nh2.png") no-repeat !important;
      }
      .bg3 {
        background: url("../../../../assets/image/parkImg/nh3.png") no-repeat !important;
      }
      .bg4 {
        background: url("../../../../assets/image/parkImg/nh4.png") no-repeat !important;
      }
      .bg5 {
        background: url("../../../../assets/image/parkImg/nh5.png") no-repeat !important;
      }
      .bg6 {
        background: url("../../../../assets/image/parkImg/nh6.png") no-repeat !important;
      }
    }
  }
  .security-text {
    font-size: 1.4vw;
    color: #6b6b6b;
    margin-left: 25%;
    // transform: translateX(-50%);
    p {
      margin: 1% 0;
    }
  }
  .security-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    margin-top: 40px;
    .security-item {
      width: 24%;
      img {
        width: 100%;
      }
      div {
        margin: 30px 0;
      }
    }
  }
}
</style>